import * as React from "react"
import PropTypes from "prop-types"
import BlogHeader from "./BlogHeader"
import Footer from "./Footer"
import "../css/index.scss"
import "../css/blogLayout.scss"
import SvgDefinitions from "./SvgDefinitions"

const BlogLayout = ({ children }) => {
  return (
    <div className="content blog">
        <SvgDefinitions />
        <BlogHeader />
        <div id="lSide"></div>
        <div id="mid" className="blog">{children}</div>
        <div id="rSide"></div>
        <Footer />
    </div>
  )
}

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogLayout
