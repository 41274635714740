import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import Seo from "../components/seo";
import BlogLayout from "../components/BlogLayout";
export const _frontmatter = {
  "title": "Test MDX Page",
  "path": "/test",
  "author": "Mihailo Evans",
  "date": "2020-02-03T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <BlogLayout mdxType="BlogLayout">
      <Seo title="Test MDX" mdxType="Seo" />
      <h1>{`Test Heading`}</h1>
      <p>{`This is a test paragraph inside of the mdx file`}</p>
      <p>{`a
b
c
d`}</p>
      <p>{`Therefore,
I `}<em parentName="p">{`should`}</em>{` be able to write `}<strong parentName="p"><em parentName="strong">{`multiple lines`}</em></strong>{`
that all contribute to the same sentence.`}</p>
      <ul {...{
        "className": "contains-task-list"
      }}>
        <li parentName="ul" {...{
          "className": "task-list-item"
        }}><input parentName="li" {...{
            "type": "checkbox",
            "checked": true,
            "disabled": true
          }}></input>{` `}{`Hello World`}</li>
      </ul>
      <blockquote>
        <p parentName="blockquote">{`This is a quote`}</p>
      </blockquote>
      <p><inlineCode parentName="p">{`And this is an inline code block`}</inlineCode></p>
      <pre><code parentName="pre" {...{}}>{`const x = 'This is a code block'
`}</code></pre>
      <Link to="/" mdxType="Link">This is a link back to the root</Link>
    </BlogLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      