import React from "react";
import { Link } from "gatsby";

const BlogHeader = ()=>(
    <header>
        <div className="title-row">
            <h1><Link to="/" style={{textDecoration: 'none', color: 'inherit'}}>MIHAILO EVANS</Link></h1>
        </div>
        <div className="subtitle">
            <h2>Electrical / Biomedical Engineer</h2>
            <span></span>
        </div>
    </header> 
)

export default BlogHeader